import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';

import FirstImage from '../blog_images/seceri_i_kako_ih_uklopiti/img1.jpg'
import SecondImage from '../blog_images/seceri_i_kako_ih_uklopiti/img2.jpg'
import ThirdImage from '../blog_images/seceri_i_kako_ih_uklopiti/img3.jpg'
import FourthImage from '../blog_images/seceri_i_kako_ih_uklopiti/img4.jpg'
import FifthImage from '../blog_images/seceri_i_kako_ih_uklopiti/img5.jpg'
import SixthImage from '../blog_images/seceri_i_kako_ih_uklopiti/img6.jpg'


const BlogPost11 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>

<p>Od kolača i slatkiša, preko hleba i testenine, pa sve do „zdravih“ proizvoda šećeri su svuda oko nas. Većina hrane u čijem ukusu uživamo ima puno šećera, a istovremeno svi pričaju kako nam šećeri smetaju, kako treba da ih izbegavamo ili ih čak nazivaju „belom smrću“. U ovoj konfuziji, nije čudno što se ljudi često osećaju zbunjeno i imaju puno pitanja, poput: „Da li treba u potpunosti izbaciti šećer?“ ili „Da li svaki kolač uništava trud ka zdravom životu?“.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Odgovor, kao i obično, leži u balansu. Šećeri nisu ni apsolutno dobri ni apsolutno loši. Naša biološka potreba za slatkim je duboko ukorenjena u evoluciji i društvu, ali nekontrolisana konzumacija dovodi do brojnih zdravstvenih problema. Ovo ne znači da je rešenje potpuna zabrana, jer ekstremna rešenja retko daju dobre rezultate. Suprotstavljanje urođenim željama često vodi ka frustraciji i češćem prejedanju, pa je neophodno naći tačku balansa između preterivanja i odricanja.</p>
<p>U ovom tekstu, razmotrićemo kako šećeri utiču na telo, gde leži granica između uživanja i problema, kao i kako pronaći balans koji radi za, a ne protiv nas.</p>

<h2>Šećeri: šta su i zašto ih volimo?</h2>
<p>Kada pričamo o šećerima uglavnom mislimo na proste ugljene hidrate (izgrađene od jednog ili dva molekula šećera). Za razliku od složenijih ugljenih hidrata, kada unesemo proste, telo ih brzo razlaže i koristi kao gorivo za razne procese u našem telu. Šećeri se nalaze u širokom spektru namirnica, od prirodnih izvora poput voća i meda do prerađenih proizvoda poput slatkiša, gaziranih pića i peciva.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Razlozi za našu ljubav prema šećeru imaju duboke evolutivne korene. U dugoj istoriji čoveka sladak ukus je bio signal sigurnosti, signal da jedemo nešto što će nam dati puno energije i da neko vreme ne moramo da brinemo o hrani. U svakodnevnom životu nemamo vremena da o ukusima razmišljamo u kontekstu onoga što oni stvarno jesu, već samo tražimo zadovoljenje neke potrebe. Čulo ukusa nam zapravo šalje signal za hemijski sastav onoga što nam je u ustima: slatko za energiju, gorko za otrov, kiselo za puno kiselih elektrolita, slano za puno jonskih elektrolita i umami za puno proteina. Otuda, <strong>slatko je signal za lepo</strong>, za nešto prepuno energije, što je i osnovni razlog zašto uopšte jedemo.</p>
<p>Pored biološke predispozicije, prekomerna konzumacija šećera danas je dodatno podstaknuta agresivnim marketingom i činjenicom da se šećer nalazi u mnogim proizvodima koji nemaju sladak ukus, poput preliva za salate, „zdravih“ sokova ili gotovih jela. Pošto nam mozak signalizira da više volimo namirnice sa šećerom, kompanije imaju očigledan interes da u proizvode dodaju šećere, pa to i rade. Istim interesom je vođeno to da pakovanja izgledaju privlačno i da bilbordi budu ispunjeni reklamama ukusnih i hrskavih namirnica.</p>
</SquareImage>
<Conclusion>Naša ljubav prema šećerima ima duboke evolutivne korene, ali je u savremenom svetu dodatno pojačana marketingom i sveprisutnošću šećera u mnogim proizvodima, što otežava kontrolu unosa i vodi ka prekomernoj konzumaciji</Conclusion>

<h2>Kako šećeri utiču na telo i zdravlje?</h2>
<h3>Uticaj na zdravlje</h3>
<p>Kada unosimo proste šećere, oni se momentalno razlažu do glukoze što povećava nivo šećera u krvi. Brzi porast nivoa glukoze izaziva reakciju pankreasa i lučenje insulina, koji omogućava da šećer bude usvojen od strane ćelija i na taj način vrati nivo šećera u krvi do normalne granice. Insulinski sistem ovome i služi i vrlo je efikasan u obavljanju svog posla.</p>
<p>Problem nastaje kada se ovaj skok šećera u krvi i oslobađanje insulina događa prečesto i previše intenzivno, pri čemu dolazi do <strong>insulinske rezistencije</strong>. Ovo je stanje pri kojem ćelije postanu manje osetljive na insulin i sporije usvajaju šećere iz krvotoka. Kako bi postiglo isti efekat, naše telo počinje da oslobađa veće količine insulina, što čini da ćelije postanu još manje osetljive. Neravnoteža u sistemu za kontrolu šećera vodi ka <strong>metaboličkom sindromu</strong>, grupi stanja koja uključuju gojaznost, povišen krvni pritisak i povećan rizik od dijabetesa i kardiovaskularnih bolesti. Osim velikih količina insulina, dodatni problem je što u krvotoku dugo ostaju i velike količine šećera koje remete normalno funkcionisanje tela. Kako bi smanjilo štetu od visokih koncentracija šećera u krvi, naše telo višak šećera skladišti u jetri u vidu glikogena, ali kada su kapaciteti jetre popunjeni, šećer se pretvara u masti. Ove masti se zatim skladište u jetri ili u masnim ćelijama i dodatno povećavaju rizik od <strong>masne jetre</strong> i drugih <strong>metaboličkih problema</strong>.</p>
<h3>Uticaj na mentalno stanje</h3>
<p>Osim problema sa fizičkim zdravljem, preveliki i prečesti unos šećera može napraviti probleme u biohemiji mozga. Nagli rast šećera u krvi dovodi do osećanja uzbuđenosti i blage euforije, koja u sledećem trenutku rezultuje padom energije i tromosti.</p>
<p>Dugoročno, čest visok unos šećera može doprineti razvoju depresije i anksioznosti. Jedno istraživanje pokazalo je da ako se unos šećera poveća za 100 grama dnevno dolazi do 28% povećanja pojave depresije. Ovo verovatno nije samo rezultat promene nivoa šećera u krvi, već i promena normalnog praga dopamina.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<h3>Uticaj na crevni biom</h3>
<p>Crevni mikrobiom, ključan je za normalno funkcionisanje našeg tela, uključujući i mentalno i fizičko zdravlje. Prekomeran unos šećera može narušiti raznovrsnost korisnih bakterija u crevima, stvarajući povoljne uslove za rast „loših" bakterija i gljivica poput kandide. Ova neravnoteža, poznata kao <strong>disbioza</strong>, povezana je sa povećanjem upalnih procesa u telu i mozgu, što može dodatno pogoršati simptome anksioznosti i depresije. O čemu možete pročitati u objavi posvećenoj <a href="/science/blog_post/9">anksioznosti i ishrani.</a></p>
<p>Narušen mikrobiom uzrokuje probleme sa pravilnim varenjem i apsorpcijom hrane. Loše varenje vodi nadutosti i iscrpljenosti, a dugoročno može uzrokovati manjak nekih nutijenata. Brojni nutrijenti su esencijalni, odnosno naše telo ne može da funkcioniše pravilno bez njih, pa problemi sa crevnim biomom mogu izazvati naizgled nepovezane probleme. Često su prvi pokazatelji nedostataka nutrijenata smanjena aktivnost imunog sistema, pad energije ili kognitivnih funkcija koji mogu biti povezani sa čitavim nizom drugih uzročnika.</p>
</SquareImageLeft>
<Conclusion>Prekomeran unos šećera negativno utiče na fizičko i mentalno zdravlje, izazivajući metaboličke poremećaje, promene u biohemiji mozga i narušavanje crevnog mikrobioma, što može doprineti razvoju hroničnih bolesti, depresije i anksioznosti</Conclusion>

<h2>Zavisnost od šećera</h2>
<p>Kada se govori o šećeru, često se postavlja pitanje: da li šećer zaista stvara zavisnost? Često se može čuti da je šećer „droga modernog doba", uz tvrdnje da ima sličan efekat na mozak kao psihoaktivne supstance poput kokaina. Iako ova tvrdnja zvuči alarmantno i atraktivno za naslove, potrebno je dublje istražiti šta nauka zaista kaže o ovoj temi.</p>
<p>Kada konzumiramo šećer, dolazi do oslobađanja dopamina, neurotransmitera povezanog sa osećajem zadovoljstva i nagrade. Ovo može dovesti do osećaja trenutnog zadovoljstva, ali i začaranog kruga u kojem želimo još onoga što nam stvara zadovoljstvo. Isti obrazac ponašanja može se primetiti kod svih bolesti zavisnosti, od kocke do zloupotrebe psihoaktivnih supstanci. Međutim, razlika između šećera i prave zavisnosti leži u intenzitetu i trajanju efekta.</p>
<SquareImage src={FourthImage} alt="Description">
<p>Psihoaktivne supstance izazivaju nagle i snažne skokove dopamina, dok šećer, kod većine ljudi, proizvodi blaže i kraće efekte. Ova razlika je ključna jer, iako konzumacija šećera može izazvati zadovoljstvo i neku vrstu zavisnosti, ona nije toliko snažna da bi je definisali kao klasičnu zavisnost i ostavlja nam mnogo više prostora za kontrolu i svesne odluke.</p>
<p>Neka istraživanja sugerišu da prekomerna konzumacija šećera kod životinja može izazvati ponašanje slično zavisnosti. Na primer, <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/17617461/">studije na pacovima</a> pokazuju da dolazi znakova zavisnosti kada im se pristup šećerima ponovo omogući nakon perioda ograničenog pristupa. Međutim, ovakvi modeli ne mogu u potpunosti prikazati složenost ljudskog ponašanja. Naša zavisnost od šećera, a i drugih supstanci, često je psihološke prirode i više povezana sa emocionalnim stanjem, stresom i navikama, nego sa biološkim mehanizmima.</p>
<p>Ne smatram da je preteran unos slatkiša posledica zavisnosti, radije, ovu potrebu posmatram kroz prizmu navika, obrazovanja i izbora. Trudim se da pomognem ljudima da identifikuju obrasce ponašanja koji im se ne sviđaju i preuzmu svoje navike i posredno svoj život u svoje ruke. Na primer, nikada nije cilj potpuno izbacivanje slatkiša, već uspostavljanje kontrole i poštovanje sebi postavljenog cilja. <strong>Slatkiši treba da budu povremeni užitak, a ne konstantna potreba</strong>.</p>
</SquareImage>
<p>Dakle, šećer može izazvati ponašanje koje podseća na zavisnost, ali ga ne bi trebalo svrstati u istu kategoriju sa drugim supstancama koje izazivaju zavisnost. Problem često leži u lošim navikama, pogrešnom odnosu sa hranom i emocionalnim faktorima, a ne biološkim procesima. Iz ovih razloga zavisnost od šećera bih radije uporedio sa zavisnošću od kocke, gde ni ne postoji supstanca koja izaziva zavisnost, već se radi o zavisničkom, kompulsivnom ponašanju. Svesna kontrola, balans i znanje o posledicama su ključni za rešavanje problema sa prevelikim unosom šećera.</p>
<Conclusion>Iako šećer može izazvati ponašanja slična zavisnosti, ona su više povezana sa lošim navikama i emocionalnim faktorima nego sa pravom biološkom zavisnošću. Svesna kontrola, edukacija i balansirani pristup su ključ za upravljanje unosom šećera</Conclusion>

<h2>Socijalni aspekti šećera</h2>
<p>Šećer nije samo neka tamo supstanca, već i važan deo društvenog života i kulturnih običaja. Od rođendana i slava do svakodnevnih trenutaka uživanja, šećer ima duboko ukorenjenu ulogu u našem životu. Ovaj aspekt ne možemo zanemariti kada pričamo o njegovom uticaju na naše zdravlje jer ukazuje da konzumacija šećera nije samo pitanje fiziološke potrebe, već i psiholoških i društvenih interakcija.</p>
<SquareImageLeft src={FifthImage} alt="Description">
<p>Neretko se šećer povezuje sa trenucima radosti i okupljanja. Rođendanske torte, kolači na svadbama, pa čak i svakodnevne kafe sa prijateljima često uključuju slatkiše. Ovakvi momenti igraju važnu ulogu u onome što emotivno osećamo, a to je osećaj da pripadamo, kao i osećaj povezanosti sa svojom okolinom. Međutim, takođe osećamo i socijalni pritisak koji može dodatno otežati kontrolu nad unosom šećera. Na primer, odbiti tortu može biti praktično nemoguće bez nelagode i izdvajanja.</p>
<p>Na kraju, šećer od detinjstva ima ulogu svojevrsnog emotivnog balzama. Naučeni smo i programirani da su slatkiši nagrada („Dobio si 5, vodimo te u Srce.“) ili uteha („Primio si injekciju, kupićemo ti čokoladu da ne plačeš.“). Ovaj obrazac naučenog ponašanja prenosi se u odraslo doba, gde slatkiše kupujemo sami sebi i dalje kao brz i dostupan izvor utehe i nagrade. Nažalost, ovo vodi emocionalnom prejedanju, gde šećer postaje način za nošenje sa negativnim emocijama i stresom, a ne uživanje.</p>
</SquareImageLeft>
<p>Dakle, pored bioloških potreba i psihičke zavisnosti, imamo i ogroman uticaj socijalnih faktora. Živimo u okruženju gde su šećeri svuda i svi te nutkaju kolačima i tortama. Pa razvoj nezdravog odnosa deluje gotovo neizbežan. Istovremeno, tvrdim da je većina stvari i dalje do nas i naše odluke. Šećer nije samo gorivo. On je deo naše svakodnevice, naših emocija i naših odnosa s ljudima. Razumevanjem možemo razviti zdraviji i svesniji odnos prema njemu, uživajući kada to želimo, a ne kada osećamo da moramo.</p>
<Conclusion>Šećer ima duboko ukorenjenu ulogu u našim emocijama, društvenim interakcijama i kulturnim običajima, ali svestan pristup i balans omogućavaju da uživamo u njemu kada želimo, a ne kada osećamo da moramo</Conclusion>

<h2>Balansirano uživanje</h2>
<p>Balansirano uživanje u šećerima je ključni deo održavanja zdravog odnosa prema hrani i dugoročnog zdravlja. Umesto da na šećere gledamo kao na neprijatelja koje treba u potpunosti izbegavati, oni naprotiv treba da budu deo uravnotežene ishrane. Nerazumno odricanje vodi osećaju toga da nam je nešto oduzeto i zabranjeno, što može dovesti do potpuno suprotnog efekta i nadoknađivanju oduzetog kroz prejedanje koje potom prati osećaj krivice i uopšte stvaranje lošeg odnosa sa hranom.</p>
<h3>Principi balansiranog uživanja:</h3>
<ol>
    <li>
    <p><strong>Prisutnost</strong>: Ovo važi za svu hranu, ne samo za šećere. Ključ je u tome da se jede svesno i sa namerom, a ne usputno i „sa nogu“. Odaberite trenutak ka zaista uživate u slatkišima, a ne kada vam je dosadno i ne znate šta biste. Na primer, uživanje u desertu posle obroka može biti normalan deo ishrane ako je deo šireg, dobrog izbalansiranog obroka.</p>
    </li>
    <li>
    <p><strong>Postavljanje granica, ne zabrana</strong>:Umesto „nikad ne jedem slatkiše", razumniji pristup može biti „jedem slatkiše nekoliko puta nedeljno“, ili „jedem ih posle ručka“. Ako odlučite da jedete slatkiše nekoliko puta nedeljno, možete ih planirati za situacije kada ćete zaista uživati u njima, poput rođendana ili pauze za kafu sa kolegama. Ovakav pristup ostavlja prostor da uživate bez osećaja krivice, dok istovremeno stavlja unos šećera pod vašu kontrolu.</p>
    </li>
    <li>
    <p><strong>Specijalne okolnosti</strong>: Ukoliko znate da ćete ići na neku proslavu ili okupljanje, vredi razmisliti o tome šta vas tamo očekuje. Prisustvo hrane koju trenutno smanjujete ili izbegavate je gotovo neminovno. Dobra taktika je unapred odlučiti koliko ćete pojesti i fokusirati se na društveni aspekt događaja. Odabrati „neću ništa” pokazalo se kao loša taktika za većinu ljudi, tako da je ne predlažem, ali ni „opustiću se” uglavnom nije dobro jer često vodi prejedanju i nadoknađivanju.</p>
    </li>
    <li>
    <p><strong>Zadovoljenje potrebe</strong>: Razmislite šta vam se konkretno jede. Ukoliko vam se samo jede „nešto slatko” razmotrite bolje opcije poput: tamne čokolade, domaćih poslastica sa manje prerađenih sastojaka ili voće. Sa druge strane, ukoliko vam se jede nešto konkretno, na primer jafa, traženje zdravijih opcija može biti zamka. Umesto jafe, poješćete puno nekog integralnog keksa koji vam se ni ne jede, da bi nakon puno unetog šećera želja za jafom ostala. Povremeno uživanje u desertu, posebno u društvenim situacijama, ne samo da nije štetno već doprinosi kvalitetu života.</p>
    </li>
    <li>
    <p><strong>Individualne potrebe</strong>: Ne postoji niko ko vam precizno može reći koliko treba ili koliko je dovoljno. Vi ste ti koji postavljaju svoje granice i jedini koji poznaje vaše potrebe. Svaka osoba ima različite potrebe i nivo tolerancije na šećere. Dok nekome odgovara povremeno uživanje u desertu, drugima može prijati potpuno izbegavanje prerađenih šećera. Važno je isprobati, eksperimentisati i pronaći strategiju koja funkcioniše za vas.</p>
    </li>
    <RectangleImage src= {SixthImage} alt="Description"></RectangleImage>
    <li>
    <p><strong>Kombinacija sa nutritivno bogatim obrocima</strong>: Unos slatkiša uz glavni obrok može usporiti apsorpciju šećera zahvaljujući prisustvu vlakana, proteina i zdravih masti. Ovo smanjuje nagle oscilacije šećera u krvi i produžava osećaj sitosti. Dakle, nije isto da li se slatkiši jedu nezavisno od obroka ili uz drugu hranu, posebno kada je ona višeg kvaliteta.</p>
    </li>
    <li>
    <p><strong>Izbegavanje skrivenih šećera</strong>: Znanje o prisustvu šećera u naizgled neslatkim namirnicama, poput kečapa, mesnih prerađevina ili hleba, može značajno smanjiti nepotreban unos. Jednostavan savet je birajte što prirodnije i manje prerađene proizvode.</p>
    </li>
    <li>
    <p><strong>Vreme unosa</strong>: Nije svejedno da li jedemo energetski bogate namirnice ujutru ili uveče, pre treninga ili pre spavanja. Dobro vreme za unos šećera je pre fizičke aktivnosti, poput fizičkog posla, sređivanja kuće ili treninga. Tada uneta energija može da bude brzo iskorišćena i da ne dođe do njenog skladištenja u masti.</p>
    </li>
    <li>
    <p><strong>Uživanjem bez stresa</strong>: Povremeno i kontrolisano uživanje u desertu nema značajan uticaj na trud uložen u zdraviji način života. Bitno je shvatati bitnost celokupne ishrane u odnosu na pojedinačne obroke i ne dozvoliti osećaju krivice da sabotira dugoročne ciljeve.</p>
    </li>
    <li>
    <p><strong>Razumevanje emocionalnih okidača</strong>: Pre nego što uzmete ili kupite slatkiš, zapitajte se da li vam se jede, da li ste gladni ili pokušavate da zadovoljite neku drugu potrebu. Ukoliko se radi o emocijama, treba razmotriti druge načine suočavanja s emocijama, poput šetnje, razgovora s prijateljem ili opuštanja uz knjigu.</p>
    </li>
</ol>
<Conclusion>Balansirano uživanje u šećeru podrazumeva svesno jedenje, postavljanje granica i razumevanje svojih potreba. Povremeni desert može doprineti kvalitetu života bez ugrožavanja zdravlja ili postavljenih nutritivnih ciljeva</Conclusion>

<h2>Balansirano uživanje u praksi</h2>
<ol>
    <li>
    <p><strong>Jedite svesno:</strong> Kada odlučite da pojedete nešto slatko, izbegavajte distrakcije poput telefona, televizora ili računara. Posvetite trenutak isključivo svom obroku, sedite, uživajte u hrani, ukusu i teksturi.</p>
    </li>
    <li>
    <p><strong>Postavite granice, a ne zabrane:</strong> Odlučite koliko puta nedeljno ili dnevno želite da uživate u slatkišima i pridržavajte se tog plana naredne dve nedelje. Ako shvatite da vam je potrebna promena, prilagodite se, smanjite ili povećajte unos i pratite kako se osećate.</p>
    </li>
    <li>
    <p><strong>Zadovoljenje potrebe</strong>: Ako vam se jede nešto, birajte zdravije. Ako vam se jede nešto konkretno pojedite to i nastavite dalje.</p>
    </li>
    <li>
    <p><strong>Pronađite pravi trenutak za slatkiše:</strong> Planirajte konzumaciju slatkiša u situacijama kada ćete zaista uživati, poput onih posle obroka ili tokom pauze s prijateljima. Izbegavajte usputno grickanje iz dosade ili navike.</p>
    </li>
    <li>
    <p><strong>Budite fleksibilni:</strong> Ako se dogodi neka posebna prilika, poput proslave ili rođendana, dozvolite sebi uživanje bez osećaja krivice. Ne dozvolite da jedan desert sruši vašu posvećenost zdravlju. Važno je šta radite većinu vremena, a ne u pojedinačnim situacijama.</p>
    </li>
    <li>
    <p><strong>Razmotrite emocionalne okidače:</strong> Ako primetite da jedete zbog emocija, a ne iz gladi, razmotrite druge aktivnosti.</p>
    </li>
    <li>
    <p><strong>Povežite uživanje s aktivnošću:</strong> Ako planirate da pojedete energetski bogatu hranu, bolje je da to uradite ranije u toku dana i pre fizičke aktivnosti.</p>
    </li>
</ol>

<Conclusion>Balansirano uživanje u praksi znači jesti svesno, planirati trenutke uživanja u slatkišima i prilagoditi unos svojim potrebama, dok istovremeno razvijate zdrav odnos prema hrani i izbegavate prejedanje pod uticajem emocija</Conclusion>

<h2>Zaključak</h2>
<p>Šećeri nisu ni apsolutno dobri ni apsolutno loši, razlika je u načinu na koji ih uključujemo u svoju ishranu. Razumevanje njihove uloge, kako na fizičkom tako i na psihološkom i društvenom nivou, pomaže nam da preuzmemo kontrolu nad njihovim unosom i donesemo svesnije odluke.</p>
<p>Balansirano uživanje omogućava nam da uživamo u slatkišima bez osećaja krivice, dok istovremeno gradimo zdrav odnos prema hrani. Svesno konzumiranje, postavljanje realnih granica i prilagođavanje individualnim potrebama može značajno smanjiti rizik od prekomernog unosa i osećaja mržnje prema sebi.</p>
<p>Važno je zapamtiti da hrana nije samo gorivo, već je deo našeg društvenog i emotivnog iskustva. Umesto da se borimo protiv svojih prirodnih nagona, treba da naučimo kako da ih prepoznamo, razumemo i preokrenemo da rade u našu korist. Na kraju, nije cilj izbaciti šećere iz svog života, već ih staviti pod našu kontrolu i učiniti ih delom šire, održive i zdrave ishrane.</p>
<p>Nadam se da vam je ovaj tekst pomogao da sagledate šećere jz jedne drugačije perspektive i bolje razumete sopstvene potrebe. Stvaranje dobrog odnosa sa hranom je proces, a svaki korak je prilika za unapređenje. Već time što čitate ovaj tekst, napravili ste prvi korak ka osvešćivanju svojih navika. Nastavite da istražujete i gradite odnos sa hranom koji vas čini zadovoljnijim i zdravijim.</p>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
</div>
    )
}
export default BlogPost11