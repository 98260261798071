import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';

import FirstImage from '../blog_images/planovi_ishrane/img1.jpg'
import SecondImage from '../blog_images/planovi_ishrane/img2.jpg'
import ThirdImage from '../blog_images/planovi_ishrane/img3.jpg'
import FourthImage from '../blog_images/planovi_ishrane/img4.jpg'
import FifthImage from '../blog_images/planovi_ishrane/img5.jpg'
import SixthImage from '../blog_images/planovi_ishrane/img6.jpg'
import SeventhImage from '../blog_images/planovi_ishrane/img7.jpg'
import EightImage from '../blog_images/planovi_ishrane/img8.jpg'
import NinethImage from '../blog_images/planovi_ishrane/img9.jpg'


const BlogPost12 = () => {
    return (
    <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>

<p>Planovi ishrane su često prva stvar koju ljudi povezuju sa onim što nutricionista radi. Takođe su prva stvar za kojom ljudi posežu kada žele da promene svoje navike u ishrani. Na prvi pogled, planovi ishrane izgledaju kao jednostavno i lako rešenje. Neko je za tebe sve isplanirao, kaže ti sve što treba da radiš, jedeš, u kojim količinama i kada, a na tebi je „samo“ da se pridržavaš plana i da budeš disciplinovan. Međutim, kruti planovi ishrane ne uzimaju u obzir složenost ljudskih navika, svakodnevnih izazova i ljudskih želja, tako da iz oslobađajuće jednostavnosti brzo mogu postati veliko opterećenje i uzročnik stresa.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>U ovom tekstu razmotrićemo zašto su planovi ishrane popularni, koje su njihove prednosti i mane i zašto je fleksibilan, održiv pristup po mom mišljenju bolja opcija za izgradnju trajnih navika i zdravog odnosa sa hranom.</p>

<h2>Zašto su planovi ishrane toliko popularni?</h2>
<p>Planovi ishrane popularni su zato što deluju kao jednostavno rešenje za problem koji je izuzetno složen. Ljudima se sviđa ideja da neko drugi preuzme odgovornost i kaže im tačno šta da jedu, kada i u kojim količinama. Ovo smanjuje potrebu za donošenjem odluka, što je, sasvim razumljiv, primamljivo u svetu u kojem smo već preopterećeni obavezama i izborima koje svakodnevno donosimo.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Ovakav pristup ima svoje prednosti, naročito na početku promena, jer omogućava strukturisan plan i može poslužiti kao dobar vodič za početnike. Međutim, važno je razumeti da ishrana nije samo niz obroka ili kalorijskih vrednosti koje unosimo, već navika koja je duboko ukorenjena u našoj svakodnevici, kulturi, emocijama i socijalnim interakcijama. Upravo zato, planovi ishrane mogu postati izazovni za održavanje na duže staze.</p>
<p>Još jedan razlog za popularnost planova ishrane je osećaj kontrole i sigurnosti koju pružaju. Dobiti detaljan plan deluje kao dobijanje formule za uspeh, ali ovi planovi često ne pružaju dovoljno prostora za fleksibilnost i učenje. Kada se stroga pravila prekrše, što se neizbežno dešava, osoba može doživeti osećaj neuspeha ili krivice. Stoga, važno je kombinovati strukturu sa prilagodljivošću i fokusirati se na izgradnju zdravih navika koje su održive na duže staze.</p>
</SquareImage>
<p>Takođe, planovi ishrane mogu biti privlačni jer deluju „naučno” i „precizno”. Sve se zasniva na čvrstoj nauci, brojevima i medicinskim saznanjima. Međutim, svako ko se bavi ljudima zna da oni nisu „laboratorijski pacovi”. Naše želje, emocije i svakodnevni izazovi često izlaze iz okvira onoga što smo planirali ili želeli da uradimo u toku dana. Prilagodljiv pristup, edukacija i postepeno usvajanje zdravih navika pružaju dugoročno rešenje koje ne zavisi od „savršenog plana”, već od stvarnih promena koje se uklapaju u naš način života.</p>
<Conclusion>Planovi ishrane su popularni jer pružaju osećaj kontrole i smanjuju potrebu za donošenjem odluka, ali često ne nude fleksibilnost potrebnu za dugoročni uspeh</Conclusion>

<h2>Nedostaci planova ishrane</h2>
<h3><strong>Statičnost</strong></h3>
<p>Jedan od najvećih problema planova ishrane, koji ja uočavam, jeste njihova statičnost. Sa druge strane život je dinamičan, pun neočekivanih situacija, obaveza i promena raspoloženja. Plan koji funkcioniše tokom mirne nedelje, kada se sve odvija po planu i u normalnim uslovima, lako se ruši kada je suočen sa realnim stresnim danom, rođendanskom proslavom ili neplaniranim izlaskom. Kada osoba odstupi od plana, čak i minimalno, često dolazi do osećaja neuspeha i gubitka vere u sebe i u mogućnost promene, a ovaj osećaj može da vodi ka prejedanju ili odustajanju od promena ishrane u potpunosti.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<h3><strong>Psihološki aspekt</strong></h3>
<p>Drugi veliki problem leži u psihološkom aspektu hrane. Ona nije samo gorivo, hrana je deo naših emocija, uspomena, kulture i svakodnevnih interakcija. Planovi ishrane često zanemaruju ovaj aspekt, fokusirajući se isključivo na kalorije, makronutrijente i nauku iza ishrane, a previđaju važnost uživanja u hrani i njene društvene strane. Kao rezultat, ljudi osećaju odricanje, oduzimanje slobode i užitka, što vodi lošem odnosu sa hranom.</p>
<p>Još jedan izazov je <em>disinhibicioni refleks</em>, psihološki efekat koji se javlja kada je određena hrana potpuno zabranjena. Na primer, osoba je na planu ishrane koji zabranjuje tortu i nedeljama uspešno odoleva izazovima i ne popušta oslanjajući se na snagu volje. Međutim, vremenom će se želja za tortom akumulirati do tačke kada osoba popusti, a onda se ne jede jedno parče torte već koliko god ima i može da se pojede. Ovo uvodi u začarani krug odricanja, akumuliranja želje, popuštanja, osećaja krivice i ponovnog striktnog odricanja.</p>
</SquareImageLeft>
<h3><strong>Suštinski problem ostaje</strong></h3>
<p>Planovi ishrane ne rešavaju suštinski problem i ne vode boljem razumevanju i promeni navika koje su dovele do trenutnog problematičnog stanja. Oni pružaju privremeno rešenje, ali ne pomažu ljudima da razviju veštine potrebne za donošenje boljih odluka o ishrani na duže staze. Bez ovih veština, svaki plan postaje „zatvor”, a svako odstupanje od plana doživljava se kao bekstvo iz tog zatvora, često praćeno vraćanjem na stare navike kada je osoba bila srećnija i više uživala u hrani. Ovo je poput lošeg lekara koji leči temperaturu i povraćanje, ali ne postavlja pitanje šta ih je uzrokovalo i od čega pacijent zaista boluje.</p>
<SquareImage src={FourthImage} alt="Description">
<h3><strong>Zavisnost od autoriteta</strong></h3>
<p>Na kraju, planovi ishrane ishrane stvaraju zavisnost od spoljnog autoriteta poput nutricioniste, trenera ili aplikacije. Ljudi koji se oslanjaju isključivo na planove se udaljavaju od samostalnog odlučivanja, jer se konstantno bore protiv svojih prirodnih potreba, a autoritet ne može da zna i razume njihove trenutne životne okolnosti. To ih stavlja u poziciju večite zavisnosti od spoljašnjih pravila, umesto onoga što je stvarno bitno, a to je razvoj kontrole i odgovornosti prema sopstvenom telu.</p>
<p>U radu sa svojim pacijentima usredsređen sam na fleksibilan, održiv pristup koji podstiče edukaciju, razumevanje i postepenu promenu navika i koji se pokazao mnogo efikasnijim načinom za postizanje trajnih rezultata. Prava promena ne dolazi iz privremenog plana, već iz shvatanja da smo mi u kontroli i sposobni da sami biramo i oblikujemo svoju ishranu pritom vodeći računa i o svojim željama i o svome zdravlju.</p>
</SquareImage>
<Conclusion>Planovi ishrane često ignorišu psihološki aspekt hrane i ne rešavaju suštinski problem. Oni stvaraju zavisnost od spoljnog autoriteta, umesto da osnažuju ljude da samostalno donose odluke</Conclusion>

<h2>Planovi ishrane imaju svoje mesto</h2>
<p>Iako rigidni planovi ishrane imaju svoje nedostatke, ne možemo u potpunosti zanemariti činjenicu da oni mogu imati i prednosti, ali su ove situacije specifične. Na kraju krajeva, svaki alat ima svoju vrednost kada se koristi na pravi način pa ključno pitanje nije „Da li planovi ishrane funkcionišu?”, već „Kada i kome su oni zaista korisni?”.</p>
<h3><strong>Kratkoročni ciljevi i specifične potrebe</strong></h3>
<p>Planovi ishrane mogu biti od velike koristi kada postoji jasan, kratkoročni cilj ili specifična potreba. Korist od unapred definisanog plana mogu imati, na primer, sportisti koji se pripremaju za takmičenje i treba da budu u odgovarajućoj kategoriji, osobe sa zdravstvenim stanjima koja zahtevaju striktne režime, poput keto ishrane za kontrolu epilepsije, ili osobe koje moraju da postignu manju kilažu kao priprema za operaciju. U ovakvim slučajevima, plan pruža strukturu i preciznost koja je neophodna za postizanje cilja u kratkom vremenskom periodu. Kako se u ovde ni ne pokušava trajna promena navika ili trajan gubitak kilograma, svi nedostaci planova ishrane mogu da budu zanemareni. Dakle, planovi ishrane su dobro kratkoročno rešenje specifičnih problema.</p>
<h3><strong>Edukacija i uvod u promene</strong></h3>
<p>Planovi ishrane mogu biti efikasan način edukacije. Za ljude koji nisu upoznati sa osnovama nutritivnih vrednosti hrane planovi mogu biti početna tačka za učenje. Jasne smernice o veličini porcija, vremenu obroka i balansu makronutrijenata mogu im pomoći da steknu osnovno razumevanje o tome šta znači jesti „zdravo“. Na primer, klijentu koji nikada nije obraćao pažnju na to šta i koliko jede može koristiti da vidi koliko neke hrane je „normalno“ pojesti za jedan obrok i kakav osećaj sitosti „normalna“ porcija treba da stvara.</p>
<RectangleImage src= {FifthImage} alt="Description"></RectangleImage>
<h3><strong>Osećaj sigurnosti</strong></h3>
<p>Za ljude koji su izrazito nesigurni u svoje odluke o ishrani, plan pruža osećaj sigurnosti. Umesto da se suočavaju sa svakodnevnim dilemama poput „Šta treba da jedem?” ili „Da li je ovo u redu?”, jasne i precizne smernice mogu smanjiti stres i pomoći im da se fokusiraju na svoj cilj. Ovo može biti posebno korisno za one koji su tek na početku svog puta ka promeni ishrane i osećaju potrebu za temeljnom i striktnom strukturom, ali se kasnije mogu javiti problemi u „skidanju” sa plana o kojima smo već pričali.</p>
<h3><strong>Nedostatak fleksibilnosti kao prednost</strong></h3>
<p>Iako rigidnost planova ishrane navodim kao njihov glavni nedostatak, za neke ljude, rigidnost može biti prednost. Neki klijenti bolje funkcionišu kada imaju jasna pravila i veća ograničenja, posebno ako se osećaju preopterećeno izborima u drugim aspektima života. Takođe, primetio sam da nekim ljudima uopšte nije bitno šta jedu i na hranu gledaju isključivo kao na nešto što svaki dan moraju da urade, pa im plan ishrane samo pomaže da ne razmišljaju šta će danas jesti, a pri tom ne stvara osećaj da im je nešto oduzeto.</p>
<h3><strong>Planovi kao polazna tačka, a ne cilj</strong></h3>
<p>Važno je naglasiti da planovi ishrane ne bi trebalo da budu trajno rešenje, već alat koji služi kao polazna tačka ili privremena pomoć. Idealno, plan ishrane treba da vodi ka edukaciji i razvoju zdravijih navika, a ne da stvara zavisnost od spoljnog autoriteta. Umesto da ostanu „štake” koje osoba koristi zauvek, planovi bi trebalo da pomognu klijentima da poboljšaju svoje razumevanje ishrane i postanu samostalni u donošenju odluka.</p>
<p>U mojoj praksi, ponekad koristim planove ishrane kao privremeno rešenje ili kao način da klijentima pokažem kako bi njihova ishrana mogla da izgleda kada je dobro izbalansirana, ali ih uglavnom izbegavam. Nastojim da uvek objasnim da je cilj fleksibilan pristup koji bolje odražava stvarni život i lične potrebe klijenata, a da su planovi samo jedno od sredstava na putu ka dugoročnom uspehu, nikada jedini odgovor. Rečenica koju ponovim barem jednom na svakom sastanku je „Naš dogovor nije zapisan u kamenu i uvek postoji drugi način da postignemo iste rezultate”. Ljudsko telo je vrlo komplikovana mašinerija u kojoj postoje stotine poluga koje se mogu povući, tako da uvek postoji nekoliko koje će izazvati sličan rezultat.</p>
<Conclusion>Planovi ishrane mogu imati korisnu ulogu kada su privremeni alat za edukaciju, kratkoročne ciljeve ili stvaranje vere u promenu. Ipak, ključ je u prelasku na fleksibilniji pristup koji omogućava dugoročnu promenu navika bez rigidnih ograničenja</Conclusion>

<h2><strong>Bolje rešenje: Meke restrikcije</strong></h2>
<p>U svetu punom pravila i zabrana, fleksibilan pristup promeni navika može delovati suprotno svemu što ste čuli. Međutim, fleksibilnost omogućava trajnu promenu, jer se prilagođava stvarnom životu koji se retko odvija po tačno ustanovljenom planu i rasporedu. Dok planove ishrane vidim kao ograničenje i oduzimanje slobode, razumevanje sopstvenih potreba postaje temelj za razvoj zdravog odnosa prema hrani, što vidim kao najbitniji aspekt rada sa nutricionistom.</p>
<RectangleImage src= {SixthImage} alt="Description"></RectangleImage>
<p>Umesto strogih zabrana i onoga što bih nazvao „tvrde restrikcije”, fleksibilan pristup uvodi „meke restrikcije” kao jedini alat. Dok tvrde restrikcije podrazumevaju da ja klijentu kažem šta da radi, meke restrikcije polaze od pozitivnih promena na koje je klijent trenutno spreman. Ovo na primer, znači da umesto potpunog izbacivanja slatkiša, osoba odlučuje da ih jede samo posle ručka ili samo jednom u toku dana. Na ovaj način, izbegava se osećaj uskraćenosti i oduzimanja slobode, a vežba se kontrola nad potrebama i zadovoljstvom, koja ne sabotira dugoročne ciljeve.</p>
<p>Zašto meke restrikcije?</p>
<h3><strong>Prilagodljivost </strong></h3>
<p>Život je dinamičan, neočekivani pozivi, proslave, stresni dani ili putovanja često remete unapred isplanirane obroke. Mekši pristup omogućava da ovakve situacije budu doživljene kao očekivani deo normalnog života, a ne kao prepreka. Umesto krivice zbog odstupanja od plana zbog jednog lošeg obroka, fokus je na tome da većina obroka bude u skladu sa ciljevima, dok su povremene „posebne okolnosti” prihvatljive i očekivane.</p>
<SquareImage src={SeventhImage} alt="Description">
<h3><strong>Osnaživanje i edukacija</strong></h3>
<p>Jedan od najvećih benefita fleksibilnog pristupa jesu edukacija i osnaživanje. Korišćenjem dogovorenog seta jednostavnih pravila klijenti ne postaju zavisni od plana ili nutricioniste, već uče kako da razumeju svoje telo, potrebe i izazove. Ovako razvijena svest omogućava trajne promene koje ne zavise od spoljnog autoriteta, već od unutrašnje motivacije i stečenog znanja. Što znači da klijenti postaju sposobniji da u kritičnim situacijama pomognu sami sebi i donesu odluke utemeljene na znanju, te one koje uključuju sve faktore.</p>
<h3><strong>Odnos prema hrani</strong></h3>
<p>Jedna od značajnih posledica razumevanja ishrane je obesmišljavanje crno-belog razmišljanja o hrani, toga da je nešto dobro, a nešto drugo loše. Hrana je hrana. Naravno da postoji hrana koju možemo jesti svaki dan i hrana koju treba izbegavati, ali bitan je i balans, istrajnost i raznovrsnost, ne samo čelična disciplina. Ovo saznanje doprinosi zdravijem odnosu prema hrani, smanjuje osećaj krivice, a povećava osećaj samokontrole i slobode u odlučivanju. Nema strašnih grešaka, nema jedne super namirnice, nema jedinstvenog jednostavnog rešenja. Kao što ne postoji jedan obrok koji će popraviti celokupnu ishranu, ne postoji ni jedan obrok koji će je uništiti.</p>
</SquareImage>
<h3><strong>Održivost</strong></h3>
<p>Poštovanje plana ishrane zasniva se na trenutnoj motivaciji, pa kada početna euforija oko promene i prvih vidljivih rezultata opadne pred ljudima se nalaze dva izbora. Mogu ili da poštuju zapisani plan ili da se vrate na svoju „normalnu” ishranu, ili je crno ili je belo. Pre ili kasnije dolazi do pada motivacije, zamora ili samo prevelikog izazova, a kada se napravi prva „greška“, druga je iza ćoška. Sa druge strane fokus na promeni navika i na prepoznavanju ličnih potreba za cilj ima da postane novo normalno ponašanje i da vremenom zahteva sve manje motivacije i truda da se ispoštuje. Sigurno je plan lakše ispoštovati ako je on prilagođen čoveku, nego ako čovek mora da se prilagodi planu. U 9:00 treba da jedem 2 jaja i salatu zato što tako piše, sigurno je teže nego šest od sedam dana u nedelji treba da imam doručak, a drugo je mnogo bitnije i ima dugoročniji efekat.</p>
<SquareImageLeft src={EightImage} alt="Description">
<h3><strong>Fleksibilan pristup u praksi</strong></h3>
<p>U svom radu i dugogodišnjoj praksi, fleksibilan pristup često se svodi na postavljanje malih, brzo dostižnih ciljeva i postepeno uvođenje novih. Umesto da klijent promeni sve odjednom, započinjemo sa jednim ili dva koraka - na primer, uvođenjem vremena za obroke ili uređivanje namirnica za doručak. Kada se klijent oseća komforno sa uvedenim promenama, dodaju se novi elementi, a sve se temelji na njegovom osećaju i povratnim informacijama.</p>
<p>Primer iz prakse može biti klijent koji ima vremena za dva obroka u toku dana. Umesto da predlažem pet obroka u toku dana, što bi bila kruta generalna preporuka, zajedno sa klijentom razmatram kako su dva obroka raspoređena, šta bi moglo u njih da se ubaci, a šta izbaci, ili da li postoji prostor za dodavanje brze užine između njih. Kada se jednom složimo oko cilja koji želimo da postignemo, ponovo zajedno osmišljamo kako da ga izvedemo i pravimo dogovor oko konkretnih koraka.</p>
</SquareImageLeft>
<p>Fleksibilan pristup nije jednostavno rešenje. On zahteva više vremena, edukacije i angažmana, kako od nutricioniste, tako i od klijenta, alli ono što pruža zauzvrat jeste manje stresa, bolji odnos prema hrani i, što je najvažnije, održive rezultate. Umesto da budu zavisni od spoljnog autoriteta, klijenti postaju eksperti za svoje telo i ishranu.</p>
<Conclusion>Fleksibilan pristup ishrani omogućava dugoročne promene kroz prilagodljivost, edukaciju i postepeno usvajanje navika. Umesto rigidnih pravila, fokus je na razumevanju potreba i prepoznavanju ličnih izazova. Uspeh leži u prilagođenom, a ne nametnutom planu</Conclusion>

<h2>Radovi</h2>
<p>Kako sam čitav ovaj tekst napisao iz ličnog iskustva i mišljenja, što mi nije uobičajena praksa, osećao sam potrebu da dodam deo o radovima koji ukazuju na slične zaključke. Rekao bih da je moj stav u velikoj meri posledica ovih i sličnih istraživanja i da sam se upravo zato odlučio za ovakav pristup u svojoj ordinaciji. Vredi spomenuti da su sva dostupna istraživanja rađena sa relativno malo ljudi i često samo korišćenjem upitnika što smanjuje kvalitet odkrića. Razlog ovome je što su istraživanja ovog tipa jako komplikovana i teško je pratiti njihove dugoročne efekte.</p>
<SquareImage src={NinethImage} alt="Description">
<p><a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/10336790/">Starija studija</a> bavila se proučavanjem povezanosti između rigidnog i fleksibilnog pristupa ishrani sa neželjenim promenama ponašanja, poput simptoma poremećaja ishrane i promena raspoloženja. Rezultati su pokazali da su rigidne strategije povezane sa višim telesnim indeksom (BMI), povećanim problemima u ishrani i lošijim raspoloženjem. S druge strane, fleksibilni pristupi pokazali su mali stepen povezanosti sa neželjenim promenama ponašanja.</p>
<p><a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/11883916/">Rad</a> koji je, korišćenjem upitnika, pratio 192 žene koje nisu gojazne, ispitivao je kako različite strategije kontrole ishrane utiču na simptome poremećaja ishrane kod žena. Rezultati su pokazali da je rigidna kontrola ishrane značajno povezana sa većim brojem simptoma poremećaja ishrane, dok fleksibilna kontrola nije pokazala takvu povezanost. Ovo ukazuje da rigidne dijete mogu povećati rizik od razvoja poremećaja ishrane kod žena koje nisu gojazne.</p>
</SquareImage>
<p><a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/19394476/">Istraživanje</a> koje se bavilo ispitivanjem razlike između „restriktivnog jedenja" i „pokušaja mršavljenja" urađeno je na 288 žena u postmenopauzi. Njihovi zaključci su da je restriktivno hranjenje povezano sa višim indeksom telesne mase (BMI) i većom sklonošću ka prejedanju, dok pokušaji mršavljenja nisu bili značajno povezani sa povećanem BMI ili prejedanjem. Ovi nalazi sugerišu da restriktivno jedenje može biti kontraproduktivno i povezano sa negativnim efektima po telesnu težinu i ishranu.</p>
<Conclusion>Istraživanja ukazuju da rigidni planovi ishrane mogu imati neželjene posledice, uključujući viši BMI, povećan rizik od poremećaja ishrane i lošije raspoloženje. S druge strane, fleksibilne strategije kontrole ishrane pokazuju manje negativnih efekata</Conclusion>

<h2>Zaključak</h2>
<p>Promena ishrane, ili bilo koje druge navike, nije trka na 100 metara nego maraton koji zahteva strpljenje, razumevanje i prilagodljivost. Planovi ishrane mogu biti privlačni zbog njihove preciznosti i obećanja brzih rezultata, ali njihova rigidnost i nemogućnost prilagođavanja stvarnom životu često ih čini kratkotrajnim rešenjem.</p>
<p>Sa druge strane, fleksibilan pristup klijentima daje alat, edukaciju i samopouzdanje da sami preuzmu kontrolu nad svojim izborima i dugoročno održe promene koje su započeli. Ovaj pristup ne postavlja kruta pravila, već se prilagođava svakom pojedincu i pomaže mu da razvije zdrav odnos prema hrani, a uzima u obzir svakodnevne izazove i klijentove lične ciljeve.</p>
<p>Za kraj, izbor između plana ishrane i fleksibilnog pristupa je, kao i svi drugi izbori, stvar lične odluke. Nekome će više odgovarati jedno, a nekome drugo pošto nema univerzalnih rešenja. Moje viđenje je da uz fleksibilnost dolazi sloboda da se donose svesne odluke, da se uživa u hrani i da se istovremeno neguje zdravlje, pa je to pristup koji ja praktikujem i promovišem, ali ti treba da istražiš opcije i nađeš najbolji način da brineš o sebi i svom zdravlju.</p>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
    </div>
    )
}
export default BlogPost12